import { roundToNearestCent } from "src/common/price";

export const getServiceFee = (subtotal: number, isDelivery: boolean) => {
  if (isDelivery) {
    return 1.5;
  }

  let baseServiceFee = 1.5;

  if (subtotal > 20) {
    baseServiceFee += Math.ceil((subtotal - 20) / 20);
  }

  return roundToNearestCent(baseServiceFee);
};

export const getPaymentProcessingFeeWithCardIncluded = (
  totalInDollars: number,
) => {
  const constantStripeFee = 0.3;
  const constantStripePercentage = 0.029;
  return (
    (totalInDollars + constantStripeFee) / (1 - constantStripePercentage) -
    totalInDollars
  );
};

export const getServiceFeeForCateringRequest = (subtotal: number) => {
  const achFee = 5;
  const fivePercentOfSubtotal = roundToNearestCent(subtotal * 0.05);

  if (fivePercentOfSubtotal > 80) {
    return 80 + achFee;
  }

  return fivePercentOfSubtotal + achFee;
};

export const getServiceFeeForScheduledCateringOrder = (subtotal: number) => {
  const serviceFee = roundToNearestCent(subtotal * 0.05);
  return serviceFee;
};
